<template>
    <svg
        width="34px"
        height="24"
        viewBox="0 0 30 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.03711 11.7966L28.9998 11.7966M18.5138 1.10352L28.9998 11.7966L18.5138 22.4898"
            stroke="#140C2A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
